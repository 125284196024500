<template>
  <div>
    <base-snack-bar
      v-model="snackbar.show"
      :message="snackbar.message"
    />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <crud-cadastro
        v-if="!loading"
        :loading="loading"
        class="mb-3"
      >
        <crud-card
          class="pa-0 px-5 pb-8 mb-3"
          title="Dados Pessoais"
        >
          <v-row
            v-if="$vuetify.breakpoint.lgOnly"
            class="pt-5"
          >
            <v-col
              cols="2"
            >
              <v-row justify="center">
                <crud-image-uploader
                  ref="avatar"
                  v-model="avatar"
                  dialog-title="Avatar"
                  :editable="editAvatar"
                  no-data="img/no-avatar.jpg"
                  class=".rounded-circle"
                  :circle="true"
                  @upload="editAvatar = true"
                />
              </v-row>
              <v-row justify="center">
                <span class="mt-2 primary--text text--lighten-1 text-center">
                  <b>Escolha uma foto</b>
                </span>
              </v-row>
            </v-col>
            <v-col
              cols="9"
              class="pb-0 pa-0 px-0 pt-3"
            >
              <crud-pessoa-fisica-base
                class="mb-1"
                :optional="optionalFields"
                :input-outlined="true"
              />
              <crud-contato
                :telefones="telefones"
                :input-outlined="true"
              />
            </v-col>
          </v-row>
          <v-row
            v-else
            class="pt-5"
          >
            <v-col
              cols="12"
              md="3"
            >
              <v-row justify="center">
                <crud-image-uploader
                  ref="avatar"
                  v-model="avatar"
                  dialog-title="Avatar"
                  :editable="editAvatar"
                  no-data="img/no-avatar.jpg"
                  class=".rounded-circle"
                  :circle="true"
                  @upload="editAvatar = true"
                />
              </v-row>
              <v-row justify="center">
                <span class="mt-2 primary--text text--lighten-1 text-center">
                  <b>Escolha uma foto</b>
                </span>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="9"
              class="pb-0 pa-0 px-0 pt-3"
            >
              <crud-pessoa-fisica-base
                class="mb-1"
                :optional="optionalFields"
                :input-outlined="true"
              />
              <crud-contato
                :telefones="telefones"
                :input-outlined="true"
              />
            </v-col>
          </v-row>
        </crud-card>
        <crud-localizacao
          v-if="endereco"
          class="px-5 mb-3"
          title="Endereço"
          :optional="optionalFields"
        />
        <v-row
          justify="center"
          justify-sm="end"
          class="py-3"
        >
          <v-col cols="auto">
            <v-btn
              class="text-none text-white mr-3"
              color="error"
              rounded
              @click="$router.push({ path: `/${$user.get().role}` })"
            >
              <v-icon
                dark
                left
              >
                mdi-minus-circle
              </v-icon>
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              class="text-none text-white mr-3"
              color="success"
              rounded
              @click="submit"
            >
              <v-icon
                dark
                left
              >
                mdi-checkbox-marked-circle
              </v-icon>
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </crud-cadastro>
    </v-form>
  </div>
</template>;
<script>
  import { mapFields } from 'vuex-map-fields'
  import { mapState, mapActions } from 'vuex'
  import rules from '../../utils/formRules'
  import UserProfileStore, { BOOTSTRAP_PROFILE, SUBMIT, UPDPASSWORD } from '@/store/modules/userProfile'
  export default {
    data () {
      return {
        valid: false,
        rules,
        show: false,
        optionalFields: [],
        editAvatar: false,
        snackbar: { message: '', show: false },
      }
    },
    computed: {
      ...mapState('userProfile', ['telefones', 'loading', 'isEditing']),
      ...mapFields('form', ['data.avatar', 'data.endereco']),
    },
    created () {
      if (!this.$store.state.userProfile) { this.$store.registerModule(['userProfile'], UserProfileStore) }
      this.BOOTSTRAP_PROFILE()
    },
    methods: {
      ...mapActions('userProfile', [BOOTSTRAP_PROFILE, SUBMIT, UPDPASSWORD]),
      submit () {
        if (!this.$refs.form.validate()) {
          this.snackbar = {
            show: true,
            message: 'Existem campos Obrigatórios não prenchidos. Por favor, preencha-os!',
          }
          return null
        }
        this.SUBMIT({ user: this.$user, router: this.$router })
      },
      updpassword () {
        if (!this.$refs.formSenha.validate()) {
          this.snackbar = {
            show: true,
            message: 'Os campos de senha não correspondem. Por favor, corrija-os!',
          }
          return null
        }
        this.UPDPASSWORD({ user: this.$user, router: this.$router })
      },
    },
  }
</script>
